<template>
    <div class="card">
        <Toast />
        <h5>Funcionalidade</h5>

        <DataTable :value="funcionalidades" 
                class="p-datatable-gridlines" 
                dataKey="id" 
                :rowHover="true" 
                v-model:filters="filters1" 
                filterDisplay="menu" 
                :loading="loading" 
                responsiveLayout="scroll"
				:globalFilterFields="['nome','mensagem']" >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <div>
                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                        <Button type="button" 
                            icon="pi pi-pencil" 
                            label="Alterar" 
                            class="p-button p-component p-button-success mb-2 ml-3" 
                            :disabled="!disabled"
                            @click="habilitarAlteracao()"/>
                    </div>
                    <div>
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
                        </span>
                    </div>
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando dados. Por favor, aguarde!
            </template>
            <Column field="nome" header="Nome" style="width: 8rem; " />
            <Column field="mensagem" header="Mensagem" style="width: 22rem; ">
                <template #body="{ data }">
                    <div class="flex-none">
                        <InputText v-model="data.mensagem" :disabled="disabled"  style="width: 98%"/>
                    </div>
                </template>
            </Column>
            <Column style="width:2rem; text-align: center;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <InputSwitch v-model="data.ativo" :disabled="disabled"  />
                    </div>
                </template>
            </Column>
        </DataTable>
        
        <Button label="Cancelar" 
            icon="pi pi-times" 
            class="p-button p-component p-button-danger mt-4 mr-2" 
            :disabled="disabled" 
            @click="confirmarCancelamento()" />
        <Button label="Salvar" 
            icon="pi pi-save" 
            class="p-button p-component p-button-success mt-4" 
            :disabled="disabled" 
            @click="salvar()" />
    </div>
    
    <Dialog v-model:visible="dialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="cancelar()" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="prepararTela()" />
        </template>
    </Dialog>
</template>

<script>
    import FuncionalidadeService from '../../service/FuncionalidadeService'
    import {FilterMatchMode, FilterOperator} from 'primevue/api';

    export default {
        name: 'Funcionalidades',
        data() {
            return {
                funcionalidades: [],
                filters1: null,
				loading: false,
                dialog: false,
                disabled: false,
                
                toastTipoErro: 'error',
                toastTituloFalha: 'Falha',
                toastDetalheCampoObrigatorio:'Preencher os campos obrigatórios.',
                toastTipoSucesso: 'success',
                toastTituloSucesso: 'Sucesso',
                toastDetalheAtuazlizacaoSucesso: 'As funcionalidades foram atualizadas com sucesso!',
                toastDetalheAtualizacaoFalha: 'Falha ao atualizar as funcionalidades!',
                toastLife: 3000,
            }
        },
        created() {
			this.prepararTela();
		},
        methods: {
            prepararTela() {
                this.carregarFuncionalidades();
                this.initFilters1();
                this.cancelar();
                this.habilitarAlteracao();
            },
            carregarFuncionalidades() {
                this.carregarLoading();

                FuncionalidadeService.carregarFuncionalidades()
                    .then(({data}) => {
                        if (data) {
                            this.funcionalidades = data;
                        }
                    })
                    .catch(error => {
                        this.$toast.add({
                            severity:"error",
                            detail: error.response.data.message_error, 
                            life: 5000
                        });

                        if(error.response.data.code_error===403){
                            setTimeout(() => { 
                                this.$router.push('/');
                            }, 3000);
                            
                        }

                    })
                    .finally(() => {
                        this.carregarLoading();
                    });
            },
            carregarLoading() {
                this.loading = !this.loading;
            },
            initFilters1() {
				this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'mensagem': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			},
            clearFilter1() {
				this.initFilters1();
			},
            confirmarCancelamento() {
                this.dialog = true;
            },
            cancelar() {
                this.dialog = false;
            },
            validarCampos() {
                let isValid = true;

                if (this.funcionalidades != null) {
                    this.funcionalidades.forEach(funcionalidade => {
                        if (funcionalidade.mensagem == null || funcionalidade.mensagem == '') {
                            isValid = false;
                            return;
                        }
                    });
                }

                if (!isValid) {
                    this.abrirToast(this.toastTipoErro, this.toastTituloFalha, this.toastDetalheCampoObrigatorio, this.toastLife);
                    return;
                }

                return isValid;
            },
            salvar() {
                this.carregarLoading();
                if (!this.validarCampos()) return;

                FuncionalidadeService.atualizar(this.funcionalidades)
                    .then(response => {
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, this.toastDetalheAtuazlizacaoSucesso, this.toastLife);
                        this.carregarLoading();
                        this.habilitarAlteracao();
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, this.toastDetalheAtualizacaoFalha, this.toastLife)
                    });
            },
            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },
            habilitarAlteracao() {
                this.disabled = !this.disabled;
            },
        }
    }
</script>
