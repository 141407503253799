<template>
    <Funcionalidade :matricula="currentUser?.matricula" />
</template>

<script lang="js">
import Funcionalidade from '../../components/cadastro/Funcionalidade.vue'

export default {
    computed: {
        currentUser() {

            return this.$store.state.auth.user;

        }
    },

    components: { Funcionalidade }
}
</script>
